import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import "./styles/style.css";
import LoadingScreen from "./components/layouts/LoadingScreen";
// import ArabicSubscribe from "./pages/auth/ArabicSubscribe";
const Login = React.lazy(() => import("./pages/auth/login"));
const ArabicSubscribe = React.lazy(() => import("./pages/auth/ArabicSubscribe"));

function App() {
  const { isAuthLoading } = useSelector((state) => state.AuthSlice);

  return (
    <>
      {isAuthLoading && <LoadingScreen />}
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* ArabicSubscribe */}
          <Route path="/ar" element={<ArabicSubscribe />} />
          <Route path="/en" element={<Login />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
