import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getLogin = createAsyncThunk(
  "Auth/getLogin",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/subscribe`,
          {
            ...resD,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-localization": `en`,
              // Authorization: process.env.REACT_APP_BACKEND_Authorization,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const ArabicSubscribe = createAsyncThunk(
  "Auth/ArabicSubscribe",
  async (resD, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/subscribe`,
          {
            ...resD,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-localization": `ar`,
              // Authorization: process.env.REACT_APP_BACKEND_Authorization,
            },
          }
        )
        .then((res) => res.data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    isAuthLoading: false,
    error: null,
    Token: null,
    isAuthError: null,
  },
  reducers: {
    clearAuthError: (state, action) => {
      state.isAuthError = null;
    },
  },
  extraReducers: {
    [getLogin.pending]: (state, action) => {
      state.isAuthLoading = true;
      state.isAuthError = null;
    },
    [getLogin.fulfilled]: (state, action) => {
      state.Token = action.payload.accessToken;
      state.isAuthLoading = false;
    },
    [getLogin.rejected]: (state, action) => {
      state.isAuthLoading = false;
      state.isAuthError = action.payload.status;
    },
    [ArabicSubscribe.pending]: (state, action) => {
      state.isAuthLoading = true;
      state.isAuthError = null;
    },
    [ArabicSubscribe.fulfilled]: (state, action) => {
      state.Token = action.payload.accessToken;
      state.isAuthLoading = false;
    },
    [ArabicSubscribe.rejected]: (state, action) => {
      state.isAuthLoading = false;
      state.isAuthError = action.payload.status;
    },
    // ArabicSubscribe
  },
});
export const { clearAuthError } = AuthSlice.actions;

export default AuthSlice.reducer;
