import "./Loading.css";
const LoadingScreen = () => {
  return (
    <div className="Loader ">
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
